<template>
    <Guest>
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 class="mt-6 text-center text-2xl leading-9 font-extrabold text-white uppercase">
          Selecteer je club
        </h2>
      </div>
      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <TextInput v-model:value="searchQuery" :label="$t('register.club.search.label')" :placeholder="$t('register.club.search.placeholder')" border="enable" />
          <ul role="list" class="mt-6 divide-y divide-gray-200">
            <li
              class="flex items-center hover:bg-gray-100 py-4 cursor-pointer -mx-4 px-4" 
              :class="{ 
                'hover:bg-gray-300 bg-gray-200': club.id === selectedClub,
                'hover:bg-gray-100': club.id !== selectedClub 
              }"
              @click="selectClub(club)"  v-for="club in clubs" :key="club.id"
            >
              <img class="h-6 w-6" :src="club.picture ?? '/favicon.ico'" alt="">
              <div class="ml-3">
                <p class="text-xs font-medium text-gray-900">{{ club.name }}</p>
              </div>
            </li>
          </ul>

          <div v-if="showClubNotFoundWarning" class="mt-6 rounded-md bg-blue-100 border border-blue-200 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="text-sm text-blue-700">{{ $t('register.club.notFound.text') }}</p>
                <p class="mt-3 text-sm md:mt-0 md:ml-6">
                  <a href="mailto:info@scorelit.com?subject=My club is not listed=body=Please add.." class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                    {{ $t('register.club.notFound.button') }}
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </p>
              </div>
            </div>
          </div>

        </div>
        <div class="mt-6">
          <span @click="joinClubAndContinue" class="inline-block w-5/12 rounded-md shadow-sm float-right" :class="{'cursor-not-allowed': !selectedClub}">
              <button type="button" class="button w-full" :disabled="!selectedClub">
                  <i18n-t keypath="general.next" />
              </button>
          </span>
        </div>
      </div>
    </Guest>
</template>

<script>
import Guest from "@/wrappers/Guest";
import TextInput from "@/components/forms/TextInput";
import { indexClub, joinClub } from '@/helper/api/ClubHelper'
import router from "@/router";
import { debounce as _debounce } from "lodash"

const SEARCH_DEBOUNCE_TIME = 400 // in ms

export default {
    data() {
        return {
            searchQuery: "",
            clubsResponse: undefined,
            errors: {},
            selectedClub: null,
        }
    },
    computed: {
        clubs() {
            return this.clubsResponse?.data ?? []
        },
        refreshClubsDebounced () {
            return _debounce(this.refreshClubs, SEARCH_DEBOUNCE_TIME)
        },
        showClubNotFoundWarning () {
            if (!this.clubsResponse) {
                return false
            }
            const { total, per_page } = this.clubsResponse.meta
            return total < per_page
        }
    },
    created() {
        this.refreshClubs()
    },
    methods: {
        async refreshClubs(searchQuery = "") {
            this.clubsResponse = await indexClub(1, searchQuery)
        },
        async joinClubAndContinue() {
          if(!this.selectedClub) return;

          await joinClub(this.selectedClub);
          router.push({ name: "dashboard" });
        },
        selectClub(club) {
          this.selectedClub = this.selectedClub !== club.id ? club.id : null;
        },
    },
    components: {
        Guest,
        TextInput
    },
    watch: {
        searchQuery(val) {
            this.refreshClubsDebounced(val)
        }
    }
}
</script>